import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/styles/styles.scss"

const NotFound = props => (
    <Layout>
      <div
        style={{
          textAlign: "center",
          paddingTop: 120,
        }}
      >
        <SEO title="404: Not Found" />
        <h1>404: Not Found</h1>
        <p>
          <a href="https://kathleenhsu.com">Return to home</a>
        </p>
      </div>
    </Layout>
)

export default NotFound